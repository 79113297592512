import React, { useState, useEffect } from "react";
import "./TextSearch.css";
import tw from "twin.macro";
import { ReactComponent as SearchIcon } from "feather-icons/dist/icons/search.svg";
export const SearchBarComp = tw.div`relative xs:mb-0 lg:mr-6 py-0`;
export const Input = tw.input`py-2 px-4 border border-gray-500 rounded-md mr-2 focus:outline-none focus:ring focus:border-blue-300`;
export const SearchIconStyle = tw.span`lg:absolute inset-y-0 right-0 pr-6`;
export const ResponsiveSearchIconStyle = tw.span`absolute inset-y-0 right-0 pr-8  flex items-center xs:right-0 xs:pr-4`;
export const CountContainer = tw.div`mt-1 mr-3`;

const TextSearch = () => {
  const [searchText, setSearchText] = useState("");
  const [matches, setMatches] = useState([]); // Store the match positions in the text
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);

  // Function to traverse the DOM and find the text nodes
  const getTextNodes = (node) => {
    const textNodes = [];
    if (node.nodeType === Node.TEXT_NODE) {
      textNodes.push(node);
    } else {
      node.childNodes.forEach((child) => {
        textNodes.push(...getTextNodes(child));
      });
    }
    return textNodes;
  };

  // Search through the page's text and find match positions
  const findMatches = () => {
    if (!searchText) {
      setMatches([]); // Clear matches if searchText is empty
      return;
    }

    const textNodes = getTextNodes(document.body); // Get all text nodes from the body
    const regex = new RegExp(searchText, "gi");
    let match;
    let matchIndices = [];

    textNodes.forEach((node) => {
      let nodeText = node.textContent;
      while ((match = regex.exec(nodeText)) !== null) {
        matchIndices.push({
          node,
          startOffset: match.index,
          endOffset: match.index + searchText.length,
        });
      }
    });

    setMatches(matchIndices); // Store all match positions
    setCurrentMatchIndex(0); // Reset the current match index to the first one
  };

  // Scroll to the current match
  const scrollToMatch = () => {
    if (matches.length === 0) return;

    const currentMatch = matches[currentMatchIndex];
    const { node, startOffset, endOffset } = currentMatch;

    const range = document.createRange();
    range.setStart(node, startOffset);
    range.setEnd(node, endOffset);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    window.scrollTo({
      top: window.scrollY + range.getBoundingClientRect().top - 100, // Adjust scroll to show match
      behavior: "smooth",
    });
  };

  // Navigate to the next match
  const nextMatch = () => {
    if (matches.length === 0) return;
    const nextIndex = (currentMatchIndex + 1) % matches.length;
    setCurrentMatchIndex(nextIndex);
    scrollToMatch();
  };

  // Navigate to the previous match
  const prevMatch = () => {
    if (matches.length === 0) return;
    const prevIndex = (currentMatchIndex - 1 + matches.length) % matches.length;
    setCurrentMatchIndex(prevIndex);
    scrollToMatch();
  };

  // Handle Enter key press to trigger next match navigation
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      nextMatch(); // Trigger the next match when Enter key is pressed
    }
  };

  // Effect to find matches whenever searchText changes
  useEffect(() => {
    findMatches(); // Find matches when the search text changes
  }, [searchText]); // Run effect when searchText changes

  return (
    <>
      <SearchBarComp>
        <Input
          type="text"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value); // Update the search text state
          }}
          onKeyDown={handleKeyPress} // Listen for key press events, including Enter
          placeholder="Search..."
        ></Input>

        <ResponsiveSearchIconStyle>
          <SearchIcon />
        </ResponsiveSearchIconStyle>
        {/* <button onClick={prevMatch}>Previous</button>
        <button onClick={nextMatch}>Next</button> */}
      </SearchBarComp>
      <CountContainer>
        {matches.length > 0 && (
          <p>
            Match {currentMatchIndex + 1} of {matches.length}
          </p>
        )}
      </CountContainer>
    </>
  );
};

export default TextSearch;
